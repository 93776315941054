import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from '../assets/img/logo-fwz-hakim_ghazali(rsz).png';
import navLinkedinIcon from '../assets/img/nav-linkedin-icon.svg';
import navGithubIcon from '../assets/img/nav-github-icon.png';
import navMediumIcon from '../assets/img/nav-medium-icon.png';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <MailchimpForm className="align-items-center" />
                    <Col sm={6}>
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col sm={6} className="text-sm-end">
                        <div className="social-icon">
                            <a href="https://id.linkedin.com/in/fawwaz-kautsar" target='blank'><img src={navLinkedinIcon} alt="" /></a>
                            <a href="https://github.com/FawwazKautsar24" target='blank'><img src={navGithubIcon} alt="" /></a>
                            <a href="https://medium.com/@fawwaz.kautsar24" target='blank'><img src={navMediumIcon} alt="" /></a>
                        </div>
                        <p>&copy; Copyright 2022. All Right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
