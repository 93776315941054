import { Container, Row, Col } from "react-bootstrap";
import profileImg from '../assets/img/Profile_Jas (3x4).jpg';
import colorSharp2 from "../assets/img/color-sharp2 (mirror).png";
import TrackVisibility from "react-on-screen";

export const BannerProfile = () => {

    return (
        <section className="bannerProfile" id="bannerProfile">
            <Container>
                <h2>Profile</h2>
                <p className='sub-title'>Fawwaz Kautsar</p>
                
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={3}>
                        <TrackVisibility>
                            {({ isVisible }) => 
                            <div className={isVisible ? "animate__animated animate__slideInLeft animate__slow" : ""}>
                                <img src={profileImg} alt="Fawwaz Profile Pict" className="profileImg" />
                            </div>
                            }
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={9}>
                        <TrackVisibility>
                            {({ isVisible }) => 
                            <div className={isVisible ? "animate__animated animate__headShake animate__slow" : ""}>
                                <h6>Experiences :</h6>
                                <ul>
                                    <li>PT. Pindad Enjiniring Indonesia (PEI)<br/>Software Engineer (Internship) | 2023</li>
                                    <li>Assistant Lecturer<br/>Introduction to Technology and Digitalization, UPN | October 2020 – January 2021</li>
                                </ul>
                                <h6>Educations :</h6>
                                <ul>
                                    <li>Universitas Pendidikan Indonesia (UPI)<br/>Majoring S1 Computer Science | 2019 – Now</li>
                                    <li>Merdeka Belajar Kampus Merdeka (MBKM)<br/>Front-End Web and Back-End Developers, Dicoding Indonesia | February – August 2022</li>
                                </ul>
                            </div>
                            }
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colorSharp2} alt="" />
        </section>
    );
}
