import { ProjectCard } from "./ProjectCard";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import proj1_img1 from "../assets/img/proj1-img1.png";
import proj1_img2 from "../assets/img/proj1-img2.png";
import proj1_img3 from "../assets/img/proj1-img3.png";
import proj1_img4 from "../assets/img/proj1-img4.png";
import proj1_img5 from "../assets/img/proj1-img5.png";
import proj1_img6 from "../assets/img/proj1-img6.png";
import proj2_img1 from "../assets/img/proj2-img1.png";
import proj3_img1 from "../assets/img/proj3-img1.png";
import proj3_img2 from "../assets/img/proj3-img2.png";
import colorSharp2 from "../assets/img/color-sharp2.png";

export const Projects = () => {
    const projTab1 = [
        {
          title: "Fawwaz Merch",
          description: "Marketplace Products Platform",
          imgUrl: proj1_img1,
          projectUrl: "https://fawwaz-merch.fawwazkautsar.com/",
        },
        {
          title: "GemFinder",
          description: "Awesome Hidden Vacation Spot",
          imgUrl: proj1_img2,
          projectUrl: "https://gemfinder.fawwazkautsar.com/",
        },
        {
          title: "TraveResto",
          description: "Mood-boosting Restaurant Finder",
          imgUrl: proj1_img3,
          projectUrl: "https://traveresto.fawwazkautsar.com/",
        },
        {
          title: "MovFinder",
          description: "Movie Finder Aggregation",
          imgUrl: proj1_img4,
          projectUrl: "https://moviefinder.fawwazkautsar.com/",
        },
        {
          title: "EduLearn",
          description: "Online Learning Education Platform",
          imgUrl: proj1_img5,
          projectUrl: "https://edulearn.fawwazkautsar.com/",
        },
        {
          title: "Covid-19 Info",
          description: "Covid Aggregation Statistical Data",
          imgUrl: proj1_img6,
          projectUrl: "https://covid19-info.fawwazkautsar.com/",
        },
    ];
    
    const projTab2 = [
        {
          title: "Kalani App",
          description: "Student & Alumni Information Service App",
          imgUrl: proj2_img1,
          projectUrl: "https://github.com/FawwazKautsar24/Academic-Information-System-App-with-Flutter",
        },
    ];

    const projTab3 = [
        {
          title: "Creative Design",
          description: "KKN: Wowen Friendly Village",
          imgUrl: proj3_img1,
          projectUrl: "https://www.instagram.com/kkn.binong.upi/",
        },
        {
          title: "Publication",
          description: "Perbedaan Paradigma Pemrograman Declarative vs Imperative",
          imgUrl: proj3_img2,
          projectUrl: "https://medium.com/@fawwaz.kautsar24/perbedaan-paradigma-pemrograman-declarative-vs-imperative-6027928e88b8",
        },
    ];
    
    return (
        <section className="project">
            <Container>
                <Row id="projects">
                    <Col size={12}>
                        <h2>Projects</h2>
                        <p>Here are some of my portfolio.</p>
                        
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                          <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                              <Nav.Link eventKey="first">Website</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">Mobile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third">Others</Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <Row>
                                {projTab1.map((project, index) => {
                                  return (
                                    <ProjectCard 
                                      key={index}
                                      // title={project.title} 
                                      // description={project.description} 
                                      // imgUrl={project.imgUrl} 
                                      {...project}
                                    />
                                  )
                                })}
                              </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Row>
                                {projTab2.map((project, index) => {
                                  return (
                                    <ProjectCard 
                                      key={index}
                                      {...project}
                                    />
                                  )
                                })}
                              </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <Row>
                                {projTab3.map((project, index) => {
                                  return (
                                    <ProjectCard 
                                      key={index}
                                      {...project}
                                    />
                                  )
                                })}
                              </Row>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2} alt="" />
        </section>
    );
}
